<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-form inline>
          <el-form-item label="时间">
            <el-date-picker v-model="time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="query">查询</el-button>
            <el-button type="primary" plain @click="handleExport">导出</el-button>
          </el-form-item>
        </el-form>
      </template>
      <template>
        <el-table ref="table" :data="tableData" v-loading="tableLoading" border highlight-current-row height="100%">
          <el-table-column prop="tenantCode" label="租户编码" align="center" />
          <el-table-column prop="tenantName" label="租户名称" align="center" />
          <el-table-column prop="accountCount" label="账户总数" align="center" />
          <el-table-column prop="accessCount" label="登录访问数" align="center" />
          <el-table-column prop="accessRatio" label="登录访问率" align="center" />
        </el-table>
      </template>
    </common-layout>
  </div>
</template>
<script>
import api from '@/api/oauth/access'
import dayjs from 'dayjs'
export default {
  data() {
    return {
      time: [],
      form: {},
      tableData: [],
      tableLoading: false,
    }
  },
  mounted() {
    const start = new Date()
    const end = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
    this.time = [start, end]
    this.query()
  },
  methods: {
    query() {
      const params = {
        startDay: dayjs(this.time[0]).format('YYYY-MM-DD'),
        endDay: dayjs(this.time[1]).format('YYYY-MM-DD'),
      }
      this.tableLoading = true
      api
        .getList(params)
        .then(({ data }) => {
          this.tableData = data
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleExport() {
      const params = {
        startDay: dayjs(this.time[0]).format('YYYY-MM-DD'),
        endDay: dayjs(this.time[1]).format('YYYY-MM-DD'),
      }
      window.open(`/api/tenant-user/statistics/back_end/exportDetail?startDay=${params.startDay}&endDay=${params.endDay}`)
    },
  },
}
</script>
